import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang/lang";
// import jQuery from "jquery";
import "@/plugins/feather";
import "@/plugins/fontawesome";

import { BootstrapVue } from "bootstrap-vue";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// axios
import VueAxios from "vue-axios";
import apiCall from './axios/index';
import endpoints from './axios/endpoints';

// vue-validate
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import moment from 'moment'

import { library } from "@fortawesome/fontawesome-svg-core";
import {faEye,faEyeSlash} from "@fortawesome/free-solid-svg-icons";
library.add(faEye,faEyeSlash);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);
import mixins from "@/mixins/index";
import PortalVue from "portal-vue";
// adding global mixin
// import globalMixin from './mixins/Global';
// Vue.mixin(globalMixin);


Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueAxios);
Vue.use(PortalVue);

Vue.prototype.$apiCall = apiCall;
Vue.prototype.$apiBus = endpoints;
Vue.prototype.moment = moment

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.mixin(mixins);

localize("en", en);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// const $ = jQuery;
// window.$ = $;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
