import i18n from "@/lang/lang";
export default {
  state: {
    languages: [
      {
        name: "English",
        code: "ENG",
      },
      {
        name: "Chinese",
        code: "CHI",
      },
    ],
    activeLanguage: null,
  },
  getters: {
    activeLanguge: (state) =>
      state.activeLanguage ||
      localStorage.getItem("i18n")?.toLocaleUpperCase() ||
      "ENG",
    languages: (state) => state.languages || [],
  },
  mutations: {
    activateLanguage(state, data) {
      state.activeLanguage = data;
      i18n.locale = state.activeLanguage.toLowerCase();
      localStorage.setItem("i18n", state.activeLanguage.toLowerCase());
    },
    setLanguages(state, data) {
      state.languages = data;
    },
  },
  actions: {
    setLanguages({ commit }, data) {
      commit("setLanguages", data);
    },
    setActivateLanguage({ commit }, data) {
      commit("activateLanguage", data);
    },
  },
};
