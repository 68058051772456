import { data } from "jquery";
import Vue from "vue";
import Vuex from "vuex";
import lang from "./modules/lang";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products:[],
    packages:[],
    loading: false,
    CategoryList:[],
    currentCategory: null,
    subCategoryList:[],
    currentSubCategory: null,
    sideBarLoading:false,
    serviceList:[],
    medicalformData:{
      HEART_ATTACK:null,
      HIGH_BLOOD_PRESSURE:null,
      GYNE_DISEASE:null,
      ASTHMA:null,
      CANCER:null,
      BONE_METAL_IMPLANT:null,
      DIABETES:null,
      CONSTIPATION:null,
      KIDNEY_DISEASE:null,
      OTHERS:null,
      DEPRESSION_ANXIETY:null,
      INSOMNIA:null,
      BONE_METAL_IMPLANT:null,
      DIABETES:null,
      CONSTIPATION:null,
      KIDNEY_DISEASE:null,
      OTHERS:null,
      DEPRESSION_ANXIETY:null,
      INSOMNIA:null,
      SKIN_INFLAMMATION_ALLERGY:null,
      PREGNANCY:null,
      NONE_OF_ABOVE:null,
      BACK_WAIST:null,
      BREAST_CHEST:null,
      NECK_SHOULDER_RHEUMATISM:null,
      LYMPHATIC_BLOCK:null,
      STOMACH_KIDNEY:null,
      OVARIAN_HIP_AREA:null,
      STRESS:null,
      ENDOCRINE_IMBALANCE:null,
      LIVER_GALL:null,
      HEADACHE_INSOMNIA:null,
      HEART_LUNGS:null,
      WEAKNESS_COLD:null,
      EXCESS_FAT:null,
      LIMBS:null,
      BLOCKED_MERIDIANS:null,
      HEATY_DAMP:null,
      DARK_EYE_CIRCLES:null,
      EYE_BAG:null,
      CHOLESTEROL_OIL_SPOTS:null,
      SCARS:null,
      SAGGY:null,
      FRECKLES:null,
      DEHYDRATED:null,
      SENSITIVE:null,
      CREATED_BY:null,
    },
    customerPackageDetails:[],
    customerTransactionsData:[],
    cartItems: [],
    customerWalletHistoryDetails:[],
    customerAppointmentHistoryDetails:[]
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getCartItems(state) {
      return state.cartItems;
    },
    getPackages(state) {
      return state.packages;
    },
    loading: state => state.loading,
    sideBarLoading: state => state.sideBarLoading,
    getCategoryList(state) {
      return state.CategoryList;
    },
    getSubCategoryList(state) {
      return state.subCategoryList;
    },
    getCurrentSubCategoryList(state) {
      return state.subCategoryList.filter(cat => cat.SERVICE_CATEGORY_SYS_ID === state.currentCategory);
    },
    getServiceList(state) {
      return state.serviceList;
    },
    getCurrentServiceList(state) {
      return state.serviceList.filter(cat => cat.SERVICE_SUB_CATEGORY_SYS_ID === state.currentSubCategory);
    },
    customerPackageDetails(state){
      return state.customerPackageDetails;
    },
    customerTransactionsData(state){
      return state.customerTransactionsData;
    },
    medicalformData(state){
      return state.medicalformData;
    },
    customerWalletHistoryDetails(state){
      return state.customerWalletHistoryDetails;
    },
    getcustomerAppointmentHistoryDetails(state){
      return state.customerAppointmentHistoryDetails;
    },
    isWalletPresent(state){
      var isWalletPresent = state.cartItems.some(function(el){ return el.ITEM_TYPE === 'CREDIT_WALLET'});
      return isWalletPresent;
    },
    focWalletAmount(state){
      let existedValue = state.cartItems.find(el => el.ITEM_TYPE === 'FOC_WALLET_AMOUNT');
      return existedValue ? parseFloat(existedValue.TOTAL_PRICE).toFixed(2) : 0;
    },
    creditWalletAmount(state){
      let existedValue = state.cartItems.find(el => el.ITEM_TYPE === 'CREDIT_WALLET');
      return existedValue ? parseFloat(existedValue.TOTAL_PRICE).toFixed(2) : 0;
    },
    getLoginUserDate(){
      let user = window.localStorage.getItem('userInfo');
      let user_data = user ? JSON.parse(user) :false;
      if (user_data && !user_data.TaxPercentage ) {
          user_data.TaxPercentage = 0;
      }
      return user_data
    },
    beforeTaxTotalAmount(state){
      let data = state.cartItems;
      let price = 0;
      let unit_price = 0;
      let qty = 0;
      data.forEach(item =>{
          unit_price = 0;
          qty = 0;
          if (item.UNIT_PRICE && item.UNIT_PRICE != 'null') {
            if (item.ITEM_TYPE != 'FOC_WALLET_AMOUNT') {
              unit_price =  item.UNIT_PRICE;
              qty = item.QTY;
            }
          }
          price += parseInt(qty) * parseFloat(unit_price);
      });
      return parseFloat(price).toFixed(2);
    },
    taxApplyAmount(state){
      let data = state.cartItems;
      let price = 0;
      let unit_price = 0;
      let qty = 0;
      data.forEach(item =>{
          unit_price = 0;
          qty = 0;
          if (item.UNIT_PRICE && item.UNIT_PRICE != 'null') {
            if (item.ITEM_TYPE != 'CREDIT_WALLET' && item.ITEM_TYPE != 'FOC_WALLET_AMOUNT') {
              unit_price =  item.UNIT_PRICE;
              qty = item.QTY;
            }
          }
          price += parseInt(qty) * parseFloat(unit_price);
      });
      return parseFloat(price).toFixed(2);
    },
    taxAmount(state,getters){
        // S.P*(TAX% / 100);
        let tax_amount = 0;
        if (getters.getLoginUserDate && getters.getLoginUserDate.GstApplicable == 'Y') {
            tax_amount += Math.round(parseFloat(getters.taxApplyAmount) * (parseInt(getters.getLoginUserDate.TaxPercentage)/100));
        }
        return tax_amount;
    },
    grandTotal(state,getters){
        let total = 0;
        total += parseFloat(getters.beforeTaxTotalAmount) + ((getters.getLoginUserDate && getters.getLoginUserDate.GstApplicable == 'Y') ? parseFloat(getters.taxAmount) : 0);
        return parseFloat(total).toFixed(2);
    },
    totalOrderQty(state){
        let data = state.products;
        let qty = 0;
        data.forEach(item =>{
            qty += item.QTY;
        });
        return qty;
    },
  },
  mutations: {
    addPackages(state, data){
      state.packages = data;
    },
    updatePackages(state, items) {
      state.packages = items;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setSidebarLoading(state, loading){
      state.sideBarLoading = loading;
    },
    // customer page
    addcustomerTransactionsData(state, list){
      state.customerTransactionsData = list;
    },
    addCustomerPackageDetails(state, items) {
      state.customerPackageDetails = items;
    },
    addMedicalform(state, list){
      state.medicalformData = list;
    },

    AddToCategories(state, data) {
      state.CategoryList = [...new Set([...state.CategoryList, ...data])];
    },
    updateActiveCategory(state, data) {
      state.currentCategory = data;
    },
    AddToSubCategories(state, data) {
      state.subCategoryList = [...new Set([...state.subCategoryList, ...data])];
    },
    updateActiveSubCategory(state, data) {
      state.currentSubCategory = data;
    },
    AddToServices(state, data) {
      state.serviceList = [...new Set([...state.serviceList, ...data])];
    },
    addProducts(state, products){
      state.products = products;
    },
    addToCart(state, items) {
      state.cartItems = items;
    },
    updateCart(state, data) {
      let products = [...new Set([...state.cartItems])];
      if (data.ITEM_TYPE == 'CREDIT_WALLET') {
        const index = products.findIndex(i => (i.ITEM_TYPE === data.ITEM_TYPE));
        products[index] = data;
      } else {
        const index = products.findIndex(i => (i.ITEM_ID === data.ITEM_ID && i.ITEM_TYPE === data.ITEM_TYPE));
        products[index] = data;
      }
      state.cartItems = products;
    },
    addCustomerWalletHistoryDetails(state, items) {
      state.customerWalletHistoryDetails = items;
    },
    addCustomerAppointmentHistoryDetails(state, items) {
      state.customerAppointmentHistoryDetails = items;
    },
    updateCustomerAppointmentHistoryDetails(state) {
      state.customerAppointmentHistoryDetails = [];
    },
  },
  actions: {
    AddToCategories({ commit }, data) {
      commit("AddToCategories", data);
    },
    updateActiveCategory({ commit }, data) {
      commit("updateActiveCategory", data);
    },
    AddToSubCategories({ commit }, data) {
      commit("AddToSubCategories", data);
    },
    updateActiveSubCategory({ commit }, data) {
      commit("updateActiveSubCategory", data);
    },
    AddToServices({ commit }, data) {
      commit("AddToServices", data);
    },
    addProducts({ commit }, data) {
      commit("addProducts", data);
    },
    addToCart({ commit }, data) {
      commit("addToCart", data);
    },
    updateCart({ commit }, data) {
      commit("updateCart", data);
    },
  },
  modules: {
    lang,
  },
});
